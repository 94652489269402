body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  font-size: 14px;
}
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
#root {
  overflow-x: hidden;
  scroll-behavior: smooth;

  height: 100%;
}
/* html {
  overflow: hidden;
  scroll-behavior: smooth;
} */
.ant-scrolling-effect {
  width: calc(100% - 5px) !important;
}
.ant-modal-body {
  flex: auto;
  overflow: hidden auto;
  padding: 16px 20px 20px 20px;
}
.ant-modal-content {
  max-height: calc(100vh - 20px);
  flex-direction: column;
  display: flex;
  padding: 0px !important;
}
.left-content-admin {
  width: 300px;
}
.not-allowed {
  cursor: not-allowed;
}
.visible-hidden {
  visibility: hidden !important;
}
.cb1 .ant-modal-confirm-btns {
  justify-content: center;
}
.cb1 .ant-modal-confirm-body {
  width: 100%;
}
.editable-row .ant-form-item-explain {
  position: absolute;
  top: 96%;
}
.editable-row .ant-form-item-explain-error {
  font-size: 12px;
}
.right-content-admin {
  width: calc(100% - 300px);
}
body::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: unset;
  margin: 5px 0;
}

.ant-table .ant-table-tbody > tr.ant-table-row:hover .list-button-hover {
  display: flex;
}

.list-button-hover {
  display: none;
}
.loading-modal {
  z-index: 2000;
}
.nowrap {
  flex-wrap: nowrap;
}
.breadcrumb {
  box-shadow: inset 0 5px 10px #ebebeb;
  padding: 15px 0;
  background: #f7f7f7;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.ant-picker-cell-inner {
  min-height: 24px;
  height: unset !important;
}
.text-uppercase {
  text-transform: uppercase;
}

.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: flex !important;
}
.ant-pagination-item-link {
  border: 1px solid #e9ebec !important;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px rgba(0, 81, 139, 0.15);
  width: 32px;
  background: #fff !important;
}
.ant-pagination-item {
  border: 1px solid #e9ebec !important;
  border-radius: 6px !important;
  box-shadow: 0px 1px 3px rgba(0, 81, 139, 0.15);
}
.ant-pagination-item a {
  font-weight: 600;
  font-size: 14px;
  color: #6a7688;
}
.ant-pagination-item-active {
  background: #2260bd;
}
.ant-pagination-item-active a {
  color: #ffffff;
}
.ant-pagination-options .ant-select .ant-select-selector {
  box-shadow: 0px 1px 3px rgba(0, 81, 139, 0.15);
  border: 1px solid #e9ebec;
  height: 32px !important;
  align-items: center;
}
.ant-pagination-options
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  font-weight: 600;
  font-size: 14px;

  color: #6a7688;
}

.ant-modal-wrap{
  display: flex;
  align-items: center;
}
.ant-modal {
  padding-bottom: 0;
  top: 0px
}
.sub-column-table {
  font-size: 13px;
  color: #666666;
  font-style: italic;
}

.loading-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
body::-webkit-scrollbar-thumb {
  background: #c5ced9;
  border-radius: 30px;
}

* {
  font-family: "Inter", sans-serif;
  -webkit-overflow-scrolling: touch;
}
.layout-center {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.svg-primary svg path {
  fill: #154398;
}
.layout-max-width {
  max-width: 100vw;
  width: 100vw;
}
@media only screen and (min-width: 2000px) {
  .layout-max-width {
    max-width: 1090vw;
  }
}
.ant-breadcrumb-link {
  font-size: 12px;
}
.ant-breadcrumb li:last-child {
  font-weight: 600;
}

.title-header-page {
  font-weight: 600;
  font-size: 24px;
  color: #154398;
  margin: 30px 0px;
}
/* .ant-form-item-label {
  font-weight: 600;
} */
.ant-form-item {
  margin-bottom: 16px;
}

.w-100 {
  width: 100%;
}
.w-100vw {
  width: 100vw;
}
.h-100 {
  height: 100%;
}
.h-100vh {
  height: 100vh;
}
.pointer {
  cursor: pointer;
}
.center {
  display: flex;
  align-items: center;
}
.text-underline {
  text-decoration: underline;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.text-ellipsis-2 {
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}
.text-ellipsis-3 {
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
}
.text-ellipsis-4 {
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
}
.text-ellipsis-5 {
  -webkit-line-clamp: 5 !important; /* number of lines to show */
  line-clamp: 5 !important;
}
.v-hidden {
  visibility: hidden;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
  border: unset;
}
/* .ant-modal-wrap {
  z-index: 1099;
}
.ant-notification {
  z-index: 1100;
} */

.ant-table-thead::first-letter,
.ant-table-cell::first-letter {
  text-transform: capitalize;
}
.destroy-close-icon .ant-notification-notice-close {
  display: none;
}
.price-number {
  background: rgba(0, 197, 144, 0.1);
  border-radius: 4px;
  padding: 3px 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */

  /* main color */

  color: #154398;
}

.btn-linear-1 {
  height: 42px !important;
  padding: 0px 30px;
}

.btn-linear-2 {
  height: 45px !important;
  padding: 0px 30px;
}

.ant-input-number {
  width: 100% !important;
  border-radius: 8px !important;
}
.ant-input-number-input {
  height: 37px !important;
}
.mh-36 {
  min-height: 36px;
}

.btn-not-background {
  font-weight: 600;
  color: #154398;
  background: #ffffff;
  border: 1px solid #154398;
  border-radius: 4px;
}

.number-comment {
  font-weight: 400;
  font-size: 12px;
  color: #154398;
}

.title-table {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 16px;
  color: #000000;
}

.scroll-modalcuston {
  flex: auto;
  overflow: hidden auto;
  max-height: calc(100vh - 232px);
  padding-right: 4px;
}

.scroll-modalcuston2 {
  flex: auto;
  overflow: hidden auto;
  max-height: calc(100vh - 232px);
  padding-right: 4px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.btn-hover:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  background: #fff !important;
  color: #1d54b9 !important;
  border: 2px solid #1d54b9;
}
.hover-shadow:hover {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}
// .btn-hover-shadow:hover {
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
// }
.bdr-8 {
  border-radius: 8px;
}
.hover-menu:hover {
  color: #ff5959 !important;
}
.tiny-in-modal-step .tox-fullscreen {
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  height: auto !important;
  width: 25% !important;
}

.wrap-box-search {
  width: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 6px;
}

.scroll-transparent {
  &::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
}

.scroll-hidden {
  &::-webkit-scrollbar, &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  &:hover {
    &::-webkit-scrollbar {
      /* width: 10px; */
      background-color: #fff !important;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ddd !important;
    }
  }
}
#page-scroll {
  scroll-behavior: smooth;
  overflow: hidden auto;
}
.modal-log-error {
  .ant-modal-header, .ant-modal-title {
    background-color: #ff4d4f !important;
  }
} 

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    // box-shadow: inset 0 0 20px 20px #23232329;
}

.tabs-custom-primary {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn, .ant-tabs-tab:hover {
    color: var(--color-primary) !important;
  }
  .ant-tabs-ink-bar {
    background: var(--color-primary) !important;
  }
}

.item-hover-dropdown {
  &:hover {
    background-color: #f0f0f0;
  }
}

.modal-no-padding-bottom {
  .ant-modal-body {
    padding-bottom: 0 ;
  }
}